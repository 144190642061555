import { OnGallery, isElementWithRelation } from '@hubcms/domain-cook';
import { GalleryData, HeroMediaStoryElement, createImageData } from '@hubcms/domain-story-elements';
import { isNonNull } from '@hubcms/utils-browser';

import { getCaptionData } from '../get-caption-data';
import { mapAudiovisual } from './mapAudiovisual';
import { mapImage } from './mapImage';
import { ElementDataMapFnWithOptions, ElementDataMapOptions } from './types';

type AllOptions = keyof ElementDataMapOptions | 'recursiveMapFn';
export const mapHeroMedia: ElementDataMapFnWithOptions<AllOptions, HeroMediaStoryElement> = (data, options) => {
  const relation = isElementWithRelation(data) ? data.relation : null;

  if (relation?.__typename === 'Picture') {
    const mappedImage = mapImage(data, options);
    if (!mappedImage) {
      return null;
    }
    return {
      type: 'image',
      ...mappedImage,
    };
  }

  if (relation?.__typename === 'Gallery') {
    return {
      type: 'gallery',
      slides: relation.relatedImages.map(relatedImage => mapRelatedGalleryImage(relatedImage, options)).filter(isNonNull),
    };
  }

  if (relation?.__typename === 'Video' || relation?.__typename === 'LiveVideo') {
    const mappedAudiovisual = mapAudiovisual(data, options);
    if (!mappedAudiovisual) {
      return null;
    }
    return {
      type: 'audiovisual',
      ...mappedAudiovisual,
    };
  }

  return null;
};

function mapRelatedGalleryImage(
  relatedImage: OnGallery['relatedImages'][number],
  options: ElementDataMapOptions,
): GalleryData['slides'][number] | null {
  if (!relatedImage || !relatedImage.content) {
    return null;
  }
  const { width, height, href_full: url } = relatedImage.content.fields.sixteenNine;
  const { caption, credit } = getCaptionData(
    undefined,
    options.sectionParams['image.credit.prefix'],
    [relatedImage.caption, relatedImage.content.fields.caption].filter(isNonNull),
    [relatedImage.content.fields.credit].filter(isNonNull),
  );
  const thumb = relatedImage.content.fields.oneOne;
  const imageData = createImageData({
    url,
    alt: caption,
    thumbUrl: thumb.href_full,
    originalWidth: width,
    originalHeight: height,
    orientation: 'SIXTEEN_NINE',
    caption,
    credit,
  });
  return {
    id: relatedImage.content.displayId,
    ...imageData,
  };
}
